/* CSS for isf core */
@import "utils.less";

// Header footer bg

#main {
  line-height: 1.5;
}

.not-for-ansatte {
  #head-wrapper {
    background-color: @color-valg;
    #head {
      .language {
        display: none;
      }
      .head-menu .link-in-head-menu a {
        span {
          display: block;
        }
        &:after {
          display: none;
        }
      }
      #header {
        a#logo {
          top: 72px;
          .font-size(32);
          font-weight: 400;
        }
        .header-samarbeid {
          margin-top: 0;
          color: @color-light;
          .font-size(19);
          line-height: 1.5;
          a {
            color: @color-light;
            border-bottom: 1px solid @color-light;
            &:hover,
            &:focus {
              background-color: @color-light;
              color: @color-link-valg;
            }
          }
        }
      }
      .head-menu .vrtx-dropdown-wrapper ul li a {
        color: @color-link-valg !important;
      }
    }
    #globalnav {
      background-color: @color-valg;
    }
  }
}

select:focus {
  border: 1px solid #964003;
}

// Link color/bg

a {
  color: @color-link-valg;
}
.backgroundOnFocusHoverUnderlinedText(@color-link-valg);

#main a:not(.button):not(.button-large) {
  &:focus,
  &:hover {
    text-decoration: none;
    .boxShadow(0px 0px 0px 5px @color-link-valg);
    background: @color-link-valg;
    color: #fff;
  }
}

.add-comment-header,
#comments-header-left a,
#main *:not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.vrtx-search-results-container div.vrtx-search-results .result h2 a,
.grid-container .vrtx-frontpage-box h2 a {
  color: @color-link-valg;
  &:hover,
  &:focus {
    color: @color-link-valg;
  }
}

.select-choice-heading {
  text-align: center;
  color: @color-valg;
}

#main a:not(.button):not(.button-large):focus,
#main a:not(.button):not(.button-large):hover {
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 0px 5px @color-valg;
  -moz-box-shadow: 0px 0px 0px 5px @color-valg;
  box-shadow: 0px 0px 0px 5px @color-valg;
  background: @color-valg;
  color: #fff;
}

// typography
.not-for-ansatte {
  h1,
  h2,
  h3,
  h4,
  h5,
  h5,
  h6 {
    font-family: @serif;
    font-weight: 400;
  }

  h1 {
    .font-size(36);
    .line-height(45);
  }

  h2,
  .ui-accordion h2.ui-accordion-header,
  .vrtx-toc-header,
  .display-as-h2,
  .vrtx-tag-cloud-title,
  .vrtx-image-listing-include-title,
  .vrtx-feedback-title,
  .subfolder-menu-alt .menu-title,
  p.topic-title,
  #vrtx-blog-listing #vrtx-additional-content > h3:first-child,
  a.comments-title,
  a.feed-title {
    .font-size(30);
    .line-height(40);
  }

  h3,
  .ui-accordion h3.ui-accordion-header,
  .vrtx-messages .vrtx-resource .vrtx-title a.vrtx-title,
  .vrtx-subfolder-menu .menu-title,
  .vrtx-toc-style-like-h3 .vrtx-toc-header,
  .display-as-h3 {
    .font-size(26);
    .line-height(34);
  }
  h4 {
    .font-size(21);
    .line-height(28);
  }

  .introduction-div, .vrtx-frontpage-box.introduction .vrtx-box-content, .vrtx-introduction, .vrtx-introduction-big, .vrtx-organizational-unit-introduction {
    .font-size(20);
    .line-height(34);
    font-weight: 400;
  }
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  button:not([class^="admin-frontpage"]):not([class^="change-positions-in-row"]) {
    height: 52px;
    background-color: @color-valg !important;
    &:focus,
    &:hover {
      background-color: @color-valg--dark !important;
    }
  }
  .header-search-expand {
    background-color: @color-valg !important;
    &:focus,
    &:hover {
      background-color: @color-valg !important;
    }
  }
  &.header-search-collapsable .header-search-expand {
    background: #737677
      url("../images/magnifier-core.svg") no-repeat 0px
      17px;
    background-size: 15px auto;
  }
}

#main {
  .navigation-links ul {
    overflow: hidden;
  }
  .navigation-links li a {
    background: @color-valg;
    color: #fff;

    &.active {
      background: @color-valg--dark;
    }

    &:after {
      background-image: url("../images/arrow-forward-white.svg");
    }
    &:hover,
    &:focus {
      color: #fff;

      background: @color-valg--dark !important;
      box-shadow: none !important;
    }
  }
  .button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-valg !important;
    &:hover,
    &:focus {
      background-color: @color-neutral !important;
    }
  }
}

.ui-tabs.ui-widget.ui-widget-content {
  .ui-tabs-nav li {
    &.ui-state-active,
    &.ui-state-hover {
      background: @color-valg;
    }
  }
}

#head-wrapper #globalnav ul li {
  a,
  a:hover,
  a:focus,
  &.vrtx-active-item a,
  &.vrtx-active-item a:hover,
  &.vrtx-active-item a:focus {
    background-color: @color-valg;
  }
}

.ui-tabs.ui-widget.ui-widget-content p.vrtx-more-external-publications a,
#vrtx-listing-completed-ongoing a {
  background-image: url("../images/arrow-forward-green-bullet.svg") !important;
}
a.vrtx-more,
div.vrtx-more a,
a.all-messages,
a.all-comments,
.footer > .vrtx-search-scope a,
a.more-url {
  &::before {
    background-image: url("../images/arrow-forward-green-bullet.svg") !important;
  }
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background-image: url("../images/icon-chevron-right-brown.svg");
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-brown.svg");
  }
}

.js .toggle-more {
  & > {
    h2:before,
    h2:before,
    h3:before,
    h4:before,
    h5:before,
    h6:before {
      background-image: url("../images/icon-chevron-right-brown.svg");
    }
  }

  &.toggle-more-active > {
    h2:before,
    h3:before,
    h4:before,
    h5:before,
    h6:before {
      background-image: url("../images/icon-chevron-down-brown.svg");
    }
  }
}

// Graphs
.d3-graph-map-choropleth {
  .slider {
    background: #F2C19E;
  }
  .slider::-webkit-slider-thumb {
    background: @color-valg;
  }
  .slider::-moz-range-thumb {
    background: @color-valg;
  }
  .slider::-webkit-slider-thumb:hover {
    background: @color-valg--dark;
  }
  .slider::-webkit-slider-thumb:focus {
    background: @color-valg--dark;
  }
  .slider::-moz-range-thumb:hover {
    background: @color-valg--dark;
  }
  .slider::-moz-range-thumb:focus {
    background: @color-valg--dark;
  }
  .range-values {
    .font-size(12);
  }
}
.d3-graph-hbar-chart {
  .hbar-bar {
    fill: @color-valg;

    &:hover,
    &:focus {
      fill: @color-valg--dark !important;
    }
  }
  .hbar-bar-leaf {
    fill: @color-valg--light !important;
  }
  .chart-back-link {
    &:hover,
    &:focus {
      background: #444 url("data:image/svg+xml;utf8,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='19px' height='14px' viewBox='0 0 19 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Epil%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard-Copy-9' transform='translate(-198.000000, -21.000000)' stroke='%23ffffff'%3E%3Cg id='Group-2' transform='translate(185.000000, 12.000000)'%3E%3Cg id='pil' transform='translate(14.000000, 10.000000)'%3E%3Cline x1='18' y1='6' x2='0' y2='6' id='Path-4'%3E%3C/line%3E%3Cpolyline id='Path-5' points='6.63157895 12 -6.71831012e-15 6 6.63157895 0'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat 10px 50% !important;
      .boxShadow(none) !important;
    }
  }
}
.d3-graph-hbar-chart-simple {
  rect[fill="rgb(84, 148, 204)"] {
    fill: @color-valg;
  }
}

.d3-graph-doughnut {
  height: 380px;
}

select {
  min-width: 200px;
  font-size: 1.6rem;
  padding-top: 8px;
  padding-bottom: 6px;
  padding-left: 13px;
  height: 45px;
}

#main .g-select a:not(.button):not(.button-large):focus,
#main .g-select a:not(.button):not(.button-large):hover {
  background: @color-valg--dark;
  box-shadow: 0px 0px 0px 5px @color-valg--dark;
  .icon.delete:before {
    color: #fff;
    opacity: .7;
  }
}


// Timeline

.frontpage-timeline-inline {
  display: inline-block;
}

#main #vrtx-main-content .overview-box h2 {
  margin-top: 60px;
}
.overview-box .vrtx-box-content {
  padding-bottom: 100px;
}

body.frontpage-timeline-page {
  .frontpage-doughnut {
    position: absolute;
    z-index: 0;
    width: calc(970px + 240px);
    right: calc(calc(calc(100vw - 970px)/2) - 240px);
    overflow: hidden;
    height: 590px;

    &:after {
      content: "";
      background: transparent url("../images/doughnut-plentiful.svg") no-repeat 0 0;
      background-size: 100% auto;
      width: 383px;
      height: 623px;
      position: absolute;
      top: 50px;
      right: 30px;
      .transform(rotate(220deg));
    }

    &.frontpage-doughnut-2 {
      bottom: 0;
      &:after {
        top: auto;
        bottom: 0;
        right: 30px;
        z-index: 0;
        .transform(rotate(350deg));
      }
    }
  }
  #footer-wrapper {
    position: relative;
  }

  #main {
    position: relative;
  }

  #main #total-main {
    .vrtx-introduction {
      border-bottom: none;
    }

    .frontpage-timeline.vrtx-frontpage-box {
      position: relative;
      z-index: 2;
      >h2 {
        margin-top: -10px;
      }
      >h2 a {
        .font-size(21);
        .line-height(28);
        font-weight: normal;
        color: @color-valg;
        position: relative;
        display: inline-block;

        &:hover,
        &:focus {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          background: none;
          color: @color-valg !important;
          font-weight: bold;
        }
        &:before {
          background: @color-valg;
          content: "";
          width: 40px;
          height: 40px;
          display: inline-block;
          .borderRadius(40px);
          margin-right: 10px;
          position: relative;
          top: 10px;
        }
        &:after {
          background: @color-valg;
          content: "";
          width: 8px;
          height: 120px;
          display: inline-block;
          position: absolute;
          top: 10px;
          left: 16px;
        }
      }
      &.frontpage-timeline-last {
        >h2:after {
          display: none;
        }
      }
      .vrtx-box-content {
        background: #fff;
        z-index: 2;
        display: none;
        position: absolute;
        top: -18px;
        left: 150px;
        background: #fff;
        padding: 35px 65px 40px 65px;
        width: 844px;
        .boxShadow(4px 4px 70px rgba(196, 196, 196, 0.52));

        h2 {
          color: @color-valg;
        }

        &:before {
          content: "";
          width: 0;
          height: 0;
          border-top: 38px solid transparent;
          border-bottom: 38px solid transparent;
          border-right: 50px solid #fff;
          position: absolute;
          left: -50px;
          top: 0;
          display: block;
        }
      }
      &.frontpage-timeline-visible {
        >h2 a {
          color: @color-valg;
          font-weight: 700;
          &:before {
            .boxShadow(0px 0px 0px 5px #F2C19E);
          }
        }
        .vrtx-box-content {
          display: block;
        }
      }
    }
  }
}

// Footer
.not-for-ansatte #footer-wrapper {
  padding-top: 30px;
  background-color: @color-valg;
  .footer-logo {
    font-family: @serif;
    font-weight: 400;
    .font-size(32);
    line-height: 1.5;
    display: block;
    a {
      line-height: 0;
    }
    img {
      height: 60px;
      width: 231px;
      max-width: 211px;
    }
  }
  .footer-logo-subtitle {
    .font-size(19);
    line-height: 1.5;
    display: block;
    margin-top: 0;
  }
  .col-1-2 .content {
    margin-top: 30px;
  }
  .col-2-5 .content {
    margin-top: 140px;
  }
  .col-3-5, .col-2-5 {
    padding-top: 0;
  }
}
&.en #footer-wrapper .footer-logo img {
  max-width: 235px;
}
.footer-bottom-row {
  background-color: @color-valg--dark;
  p {
    color: @color-light;
  }
}


@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {

  #main .vrtx-back {
    margin-top: 20px;
  }

  .not-for-ansatte {
    #head-wrapper {
      background: none @color-valg;
      #head {
        #header {
          a#logo {
            line-height: 1.22;
          }
          .header-samarbeid {
            position: static;
            margin: 10px 0 5px 0;
            .font-size(16);
          }
        }
      }
    }
    &#vrtx-frontpage #vrtx-content {
      padding-top: 55px;
    }
    #menu-wrapper .menu {
      li {
        background-color: @color-valg;

        &.active {
          background-color: darken(@color-valg, 4%);
        }
        a {
          &.toggle-navigation .icon {
            background: url("/vrtx/decorating/resources/dist/src/profile/images/responsive/nav-icon-active.svg") 14px -2px no-repeat !important;
            background-size: 24px 24px !important;
          }
          &.toggle-search .icon {
            background: url("/vrtx/decorating/resources/dist/src/profile/images/responsive/search-icon-active.svg") 0 -1px no-repeat !important;
            background-size: 21px 21px !important;
          }
        }
      }
    }
    #main {
      .navigation-links li a {
        background: @color-valg !important;
        color: #fff !important;
        padding: 15px 40px 15px 20px !important;

        &.active {
          background: @color-valg--dark !important;
        }

        &:after {
          background: transparent url("../images/arrow-forward-white.svg") no-repeat 0 0 !important;
          background-size: auto 100% !important;
        }
        &:hover,
        &:focus {
          color: #fff !important;
          background: @color-valg--dark !important;
          box-shadow: none !important;
        }
      }
    }
  }
  body.frontpage-timeline-page #main #total-main .frontpage-timeline.vrtx-frontpage-box .vrtx-box-content {
    width: 100%;
    padding: 80px 15px 30px 15px;
    position: static;

    h2:first-child {
      display: none;
    }
  }

  .not-for-ansatte #footer-wrapper .footer-logo {
    font-size: 3rem;
    height: auto;
  }

  // Map fix in responsive. todo: move to disted component
  .d3-graph-map-choropleth {
    .samples-map-choropleth {
      position: absolute;
      top: auto;
      bottom: 100px;
      left: auto;
      right: 0;
      font-size: 1.2rem;
      line-height: 1;
    }
    .current-year-map-choropleth {
      font-size: 3rem;
    }
  }
}

@media print {
  .not-for-ansatte #head-wrapper {
    background: @color-valg !important;
  }
}
